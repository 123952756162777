window.addEventListener('load', (event) => {
    //slickで一瞬縦並びを防ぐ
    $('.recommend-jobs').fadeIn(1000);
    //おすすめ求人
    $('.recommend-jobs').slick({
        autoplay: true,
        autoplaySpeed: 4000,
        arrows: true,
        dots: true,
        slidesToShow: 2,
        centerMode: true,
        centerPadding: '80px',
        infinite: true,
        accessibility: true,
        variableWidth: true,
        responsive: [{
            breakpoint: 959,
            settings: {
                autoplay: true,
                autoplaySpeed: 4000,
                arrows: false,
                slidesToShow: 2,
                centerMode: true,
                centerPadding: '80px',
                infinite: true,
                accessibility: false,
                swipe: true,
            }
        },
            {
                breakpoint: 480,
                settings: {
                    autoplay: true,
                    autoplaySpeed: 4000,
                    arrows: false,
                    slidesToShow: 1,
                    centerMode: false,
                    infinite: true,
                    accessibility: false,
                    swipe: true,
                    variableWidth: true,
                }
            }
        ]
    });

    $(".sort-tab").click(function () {
        let sortTabs = $(".sort-tab");
        $(".selected").removeClass("selected");
        $(this).addClass("selected");

        const index = sortTabs.index(this);
        $(".selected-job-container").hide().eq(index).fadeIn("slow");
    });
});
